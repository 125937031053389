import { View } from '../../App'

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getSensorTable(
  headers: any, serial_numbers: Array<string>,
  dataSetter: React.Dispatch<React.SetStateAction<any>>,
  viewSetter: React.Dispatch<React.SetStateAction<any>>) {
  const res = await fetch(REACT_APP_API_BASE_URL! + "/v1/sensor-table/" + serial_numbers, {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    viewSetter(View.ERROR)
    // throw new Error(`${res.status}: ${await res.text()}`);
  }
  const data = await res.json()
  // console.log(data)
  dataSetter(data)
}

export async function getNextId(headers: any, setter: React.Dispatch<React.SetStateAction<any>>) {
  const res = await fetch(REACT_APP_API_BASE_URL! + "/v1/next-id", {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    throw new Error(`${res.status}: ${await res.text()}`);
  }
  const data = await res.json()
  setter(data.next_response_id)
}
