import { FormInputText } from './formInputs';


function SalesForceOppId(props: {defaultValue?: string}) {

  return (
    <FormInputText
      name="oppID"
      label="SalesForce Opportunity ID"
      defaultValue={props.defaultValue}
      required
    />
  )
}

export default SalesForceOppId;
