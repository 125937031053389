
const PUBLIC_URL = 'https://api.density.io';

async function fetchJson(headers: any, serial: string) {
  const res = await fetch(PUBLIC_URL + "/v2/sensors/" + serial + "/diagnostics", {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    throw new Error(`${res.status}: ${await res.text()}`);
  }
  return res.json();
}
  
export async function fetchAndSetAll(headers: any, serial_numbers: Array<string>, setter: React.Dispatch<React.SetStateAction<any>>) {
  const allData = await Promise.all(
    serial_numbers.map((serial) => fetchJson(headers, serial))
  );

  console.log(allData)
  setter(allData)
}

export async function getUser(headers: any, setter: React.Dispatch<React.SetStateAction<any>>) {
  const res = await fetch(PUBLIC_URL + "/v2/users/me", {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    throw new Error(`${res.status}: ${await res.text()}`);
  }
  setter(res.json())
}
