import React, { useEffect, useState } from 'react';
import './App.css';
import { TokenProvider } from '@densityco/lib-common-auth';
import { CoreUser } from '@densityco/lib-api-types';
import {
  Button,
  Alert,
} from '@mui/material';
import { CustomCoreSensor } from './lib/types';
import { getUser } from './lib/api/core';
import { getNextId, getSensorTable } from './lib/api/backend';
import InputSerials from './components/InputSerials';
import ContentLoading from './components/ContentLoading';
import AutoFilledSensorTable from './components/SensorTable';
import MainForm from './components/MainForm';
import styles from './App.module.scss';


export enum View {
  INPUT = "input",
  PENDING = "pending",
  FOUND_SENSORS = "found sensors",
  ERROR = "error",
  BAD_ORG = "bad organization",
}

type Credentials = {
  headers: { [key: string]: string };
};

function allEqual(arr: Array<string>) {
  return arr.every( v => v === arr[0] )
}

function AppView({ headers }: Credentials) {
  const [view, setView] = useState<View>(View.INPUT);
  const [sensors , setSensors] = useState<Array<CustomCoreSensor> | null>(null);
  const [user, setUser] = useState<CoreUser | null>(null);
  const [nextId, setNextId] = useState<string>('');
  // const [sfOppIdReq, setSfOppIdReq] = useState<boolean>(true);
  // const [shipReqReq, setShipReqReq] = useState<boolean>(true);

  const [progress, setProgress] = useState(0);
  const timeoutSeconds = 11
  const intervalMilliseconds = 500

  function handleSubmit(serials: Array<string>) {
    setView(View.PENDING)
    getUser(headers, setUser)
    getSensorTable(headers, serials, setSensors, setView)
    getNextId(headers, setNextId)
  }

  function handleStartOver() {
    setSensors(null);
    setView(View.INPUT);
  }

  // user input sensors and moved to the next step
  // TODO: test with properly formatted but nonexistent serial numbers
  useEffect(() => {
    if (user && sensors) {
      // check if the sensors are from the same org
      const orgs = sensors.map((s) => s.organization_name)
      if (allEqual(orgs)) {
        setView(View.FOUND_SENSORS)
      } else {
        setView(View.BAD_ORG)
      }

      // // check if salesforce opp id's and shipping requests are present and the same
      // const sfoppids = sensors.map((s) => s.salesforce_opp_id)
      // const shipreqs = sensors.map((s) => s.shipping_request)
      // if
    }
  }, [user, sensors])

  // set timer if PENDING
  useEffect(() => {
    let interval: NodeJS.Timer;
    let timer: NodeJS.Timeout;
    if (view === View.PENDING) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          return oldProgress + intervalMilliseconds/(timeoutSeconds*1000)*100
        });
      }, intervalMilliseconds);

      timer = setTimeout(() => {
        clearInterval(interval);
        setView(View.ERROR);
      }, timeoutSeconds * 1000);
    } else {
      clearInterval(interval!)
      clearTimeout(timer!)
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
      setProgress(0);
    };
  }, [view]);

  function SurveyHeader() {
    return (
      <React.Fragment>
      {/* <p>Include these headers when making requests: {JSON.stringify(headers)}</p> */}
        <h1>Density RMA Survey</h1>
        <h3>for Density CX only</h3>
        <p><del>A0DJZ002, A0DKE007, A0DKF015</del></p>
      </React.Fragment>
    )
  }

  function InputView() {
    return (
      <>
        <SurveyHeader />
        <InputSerials handleSubmit={handleSubmit} />
      </>
    )
  }

  function FoundSensorsView() {
    return (
      <>
        <SurveyHeader />
        <AutoFilledSensorTable sensors={sensors} />
        <p><del>{user ? user.full_name : null}</del></p>
        <p><del>{user ? user.email : null}</del></p>
        <MainForm
          sensors={sensors}
          nextId={nextId}
        />
      </>
    )
  }

  function BadOrgView() {
    return (
      <>
        <AutoFilledSensorTable sensors={sensors} />
        <Alert className={styles.button} severity="warning">These sensors do not belong to the same customer. Please create separate RMAs for each customer.</Alert>
        <Button
          variant="outlined"
          onClick={handleStartOver}
        >
          Start Over
        </Button>
      </>
    )
  }

  function ErrorView() {
    return (
      <>
        <Alert className={styles.button} severity="error">There was an error fetching sensor info.</Alert>
        <Button
          variant="outlined"
          onClick={handleStartOver}
        >
          Start Over
        </Button>
      </>
    )
  }

  return (
    <div className={styles.container}>
      {view === View.INPUT && <InputView />}
      {view === View.PENDING && <ContentLoading progress={progress} />}
      {view === View.FOUND_SENSORS && <FoundSensorsView />}
      {view === View.BAD_ORG && <BadOrgView />}
      {view === View.ERROR && <ErrorView />}
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <TokenProvider
        fallback={<p className={styles.loading}>Loading</p>}
        children={({ headers }) => (
          <AppView
            headers={headers}
          />
        )}
      />
    </div>
  );
}

export default App;
