import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  FormGroup,
  Checkbox,
} from '@mui/material';


interface FormInputSelectProps {
  name: string;
  label: string;
  options: Array<any>;
  required?: boolean;
}

interface FormInputTextProps {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  multiline?: boolean;
}

export function FormInputSelect(props: FormInputSelectProps) {
  const { name, label, options, required } = props;
  const { control, formState: { errors } } = useFormContext();

  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{required: required}}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth variant="outlined" error={!!errors[name]}>
          <InputLabel id={label}>{label}</InputLabel>
          <Select onChange={onChange} value={value} label={label} required={required}>
            {generateSelectOptions()}
          </Select>
          {/* <FormHelperText>{errors[name] ? errors[name]?.message : ''}</FormHelperText> */}
          <FormHelperText>{errors[name] ? 'This field is required' : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
};

export function FormInputText(props: FormInputTextProps) {
  const { name, label, defaultValue, required, multiline } = props;
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={{required: required}}
      render={({ field: { onChange, value } }) => (
        <TextField onChange={onChange} value={value} label={label} fullWidth required={required || false} multiline={multiline || false} rows={multiline ? 4 : 1}
          error={!!errors[name]}
          helperText={errors[name] ? 'This field is required': ''}
        />
      )}
    />
  )
};

export function FormInputRadio(props: FormInputSelectProps) {
  const { name, label, options, required } = props;
  const { control, formState: { errors } } = useFormContext();

  const generateRadioOptions = () => {
    return options.map((option) => {
      return (
        <FormControlLabel
          key={option.value}
          value={option.value}
          label={option.label}
          control={<Radio required={required}/>}
        />
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{required: required}}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth variant="outlined" error={!!errors[name]}>
          <FormLabel id={label}>{label}</FormLabel>
          <RadioGroup value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
          <FormHelperText>{errors[name] ? 'This field is required' : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export function FormInputCheckbox(props: FormInputTextProps) {
  const { name, label, defaultValue } = props;
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || false}
      render={({ field: { onChange, value } }) => (
        <FormControl
          error={!!errors[name]}
          // helperText={errors[name] ? 'This field is required': ''}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={onChange} checked={value} />}
              label={label}
            />
          </FormGroup>
        </FormControl>
      )}
    />
  )
};
