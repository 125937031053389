import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInputText, FormInputSelect } from './formInputs';
import {
  Button,
  FormControl,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { usStates, countries } from '../lib/places';
import styles from '../App.module.scss';



export interface AddressDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setAddress: (value: string) => void;
  addressEntered: string;
  addressRecommended: string;
  submitHandler: (data: any) => void;
}


export function AddressDialog(props: AddressDialogProps) {
  const { open, setOpen, setAddress, addressEntered, addressRecommended, submitHandler } = props;
  const [selectedValue, setSelectedValue] = useState<string>('entered')

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (event: any) => {
    handleClose()
    submitHandler(event)
  }

  const handleListItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value)
    
    switch (event.target.value) {
      case 'entered':
        setAddress(addressEntered);
        break;
      case 'recommended':
        setAddress(addressRecommended);
        break;
      default:
        console.log('IDK what went wrong')
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={{alignItems: 'flex-start'}}>
      <DialogTitle>Confirm Delivery Address</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedValue}
            onChange={handleListItemClick}
          >
            <FormControlLabel sx={{ alignItems: 'flex-start' }} control={<Radio />}
              value='entered'
              label={<div><div className={styles.dialogOptionTitle}>What you entered</div><div>{addressEntered}</div></div>}
            />
            <FormControlLabel sx={{ alignItems: 'flex-start' }} control={<Radio />}
              value='recommended'
              label={<div><div className={styles.dialogOptionTitle}>Recommended</div><div>{addressRecommended}</div></div>}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}


export function AddressForm() {
  const { watch } = useFormContext();

  return (
    <React.Fragment>
    <p>Customer shipping address for return labels{watch('replace') === true && " and replacements"}</p>
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <FormInputText
          name="companyName"
          label="Company Name"
          defaultValue="LinkedIn"
          required
        />
      </Grid>
      <Grid item sm={8}>
        <FormInputText
          name="shipName"
          label="Contact Name"
          defaultValue="John Doe"
          required
        />
      </Grid>
      <Grid item sm={4}>
      <FormInputText
        name="phoneNumber"
        label="Phone Number"
        defaultValue="123-456-7890"
        required
      />
      </Grid>
      <Grid item sm={12}>
        <FormInputText
          name="address1"
          label="Street address"
          defaultValue="1600 Amphitheatre Pkwy"
          required
        />
      </Grid>
      <Grid item sm={12}>
        <FormInputText
          name="address2"
          label="Floor, Suite, etc"
        />
      </Grid>
      <Grid item sm={12}>
        <FormInputText
          name="locality"
          label="City"
          defaultValue="Mountain View"
          required
        />
      </Grid>
      <Grid item sm={8}>
        <FormInputSelect
          name="administrativeArea"
          label="State"
          options={usStates}
        />
      </Grid>
      <Grid item sm={4}>
        <FormInputText
          name="zipCode"
          label="Zip code"
        />
      </Grid>
      <Grid item sm={12}>
        <FormInputSelect
          name="country"
          label="Country"
          options={countries}
          required
        />
      </Grid>
      {watch('country') !== 'United States of America' && 
        <Grid item sm={12}>
          <FormInputText
            name="taxID"
            label="EIN/Tax ID"
            required
          />
        </Grid>
      }
      <Grid item>
      </Grid>
    </Grid>
    </React.Fragment>
  )
}
