import { useFormContext } from 'react-hook-form';
import { FormInputRadio, FormInputCheckbox } from './formInputs';
import { Grid } from '@mui/material';


const urgencyOptions = [
  {
    label: "$$$$$ - First Overnight 8AM",
    value: "first overnight 8am",
  },
  {
    label: "$$$$ - Priority Overnight 10:30AM",
    value: "priority overnight 10:30am",
  },
  {
    label: "$$$ - Standard Overnight 4:30PM",
    value: "standard overnight 4:30pm",
  },
  {
    label: "$$ - 2Day 10:30AM",
    value: "2day 10:30am",
  },
  {
    label: "$$ - 2Day 4:30PM",
    value: "2day 4:30pm",
  },
  {
    label: "$ - Ground 2-5 days",
    value: "ground",
  },
  {
    label: "$$$$ - International Priority",
    value: "international priority",
  },
  {
    label: "$$$ - International Economy",
    value: "international economy",
  },
]

function ReturningAndResolution() {
  const { watch } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item sm={8}>
        <FormInputCheckbox
        name="replace"
        label="Ship a replacement?"
        />
      </Grid>
      {watch('replace') === true &&
        <Grid item sm={8}>
          <FormInputRadio
          name="urgency"
          label="Urgency"
          options={urgencyOptions}
          required
          />
        </Grid>
      }
    </Grid>
  )
}

export default ReturningAndResolution;
