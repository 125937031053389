import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Grid,
} from '@mui/material';
import { CustomCoreSensor } from '../lib/types';
import { AddressForm, AddressDialog } from './AddressForm';
import ReturnReason from './ReturnReason';
import ReturningAndResolution from './ReturningAndResolution';
import Issue from './Issue';
import Notes from './Notes';
import styles from '../App.module.scss';
import ShippingRequest from './ShippingRequest';
import SalesForceOppId from './SalesForceOppId';

const SLACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_WEBHOOK_URL;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function postToSlack(message: string) {
  // omit headers to avoid CORS error with Slack webhook
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ text: message })
  }
  // TODO: improve error handling
  if (!SLACK_WEBHOOK_URL) {
    console.log('No Slack Webhook url')
    return
  }
  fetch(SLACK_WEBHOOK_URL, requestOptions)
    .catch(err => {
      console.log(err)
    })
}


async function fetchAddressValidation(address: object) {
  try {
    const res = await fetch("https://addressvalidation.googleapis.com/v1:validateAddress?key=" + GOOGLE_MAPS_API_KEY, {
      method: 'POST',
      body: JSON.stringify(address),
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${await res.text()}`);
    }
    const data = await res.json();
    return data
  }
  catch (error) {
    console.error(`Error: ${error}`);
  }
}

function allEqual(arr: Array<string>) {
  return arr.every( v => v === arr[0] )
}

// TODO update interface with new stuff
interface IFormInputs {
  address: string;
  returnReason: string;
  address1: string;
  address2: string;
  locality: string;
  administrativeArea: string;
}

interface MainFormProps {
  sensors: Array<CustomCoreSensor> | null;
  nextId: string;
}

function MainForm(props: MainFormProps) {
  const { sensors, nextId } = props;
  const methods = useForm<IFormInputs>()
  const [address, setAddress] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);
  const [addressEntered, setAddressEntered] = useState<string>('');
  const [addressRecommended, setAddressRecommended] = useState<string>('');

  // console.log(methods.formState.errors)
  function checkAddress(formData: IFormInputs) {
    console.log(formData)
    const searchAddress = {
      "address": {
        "regionCode": "US",
        "locality": formData.locality,
        "addressLines": [formData.address1, formData.address2]
      },
      "enableUspsCass": true
    }
    const validAddresses = fetchAddressValidation(searchAddress)
    validAddresses.then(data => {
      setAddressRecommended(data.result.address.formattedAddress)
      setAddressEntered(`${formData.address1} ${formData.address2} ${formData.locality} ${formData.administrativeArea}`)
      setOpenDialog(true)
      console.log(data)
    })
  }

  function handleSubmit(data: IFormInputs) {
    console.log('parent function called')
    console.log(data)
    console.log('selected address:', address)
    if (sensors) {
      const message = `:rotating_light: *URGENT*: New RMA processed for ${sensors.length} ${sensors[0].organization_name} sensor${sensors.length === 1 ? '' : 's'}.`
      // const csv_message = "serial number,organization name,return reason,address,\n"
      //   + sensors.map(s => `${s.serial_number},${s.organization_name},${data.returnReason},${address},`).join("\n")
      // const help_message = "1. Copy text above\n2. Paste into the <https://docs.google.com/spreadsheets/d/1A5owmEcy62EprdvnyYZxq7rTVwbje3_r6Rw09BwVFkQ/edit#gid=1394352989|RMA sheet>\n3. Click *Data > Split text to columns*"
      // postToSlack(`${message}\n\n\`\`\`${csv_message}\`\`\`\n${help_message}`)
      postToSlack(`${message}\n${nextId}`)
    }
  }

  function getDefaultSfOppId() {
    const sfoppids = sensors!.map((s) => s.salesforce_opp_id)
    if (allEqual(sfoppids)) {
      return sfoppids[0]
    } else {
      return ''
    }
  }

  function getDefaultShipReq() {
    const shipreqs = sensors!.map((s) => s.shipping_request)
    if (allEqual(shipreqs) && shipreqs[0] !== "n/a") {
      return shipreqs[0]
    } else {
      return ''
    }
  }

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete='off' onSubmit={methods.handleSubmit(checkAddress)}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {/* <SalesForceOppId defaultValue={defaultSfOppId}/> */}
            <SalesForceOppId defaultValue={getDefaultSfOppId()}/>
          </Grid>
          <Grid item sm={12}>
            <ShippingRequest defaultValue={getDefaultShipReq()}/>
          </Grid>
          <Grid item sm={12}>
            <ReturnReason/>
          </Grid>
          {(methods.watch('returnReason') === "churn" || methods.watch('returnReason') === "issue") && 
            <Grid item sm={12}>
              <Issue/>
            </Grid>
          }
          <Grid item sm={12}>
            <ReturningAndResolution/>
          </Grid>
          <Grid item sm={12}>
            <AddressForm/>
          </Grid>
          <Grid item sm={12}>
            <Notes/>
          </Grid>
          <Grid item sm={12} className={styles.gridButton}>
            <Button
              className={styles.gridButton}
              variant="outlined"
              type="submit"
            >
              Send to Slack
            </Button>
          </Grid>
        </Grid>
        <AddressDialog
          setAddress={setAddress}
          addressEntered={addressEntered}
          addressRecommended={addressRecommended}
          open={openDialog}
          setOpen={setOpenDialog}
          submitHandler={methods.handleSubmit(handleSubmit)}
        />
      </form>
    </FormProvider>
  )
}

export default MainForm;
