import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInputSelect, FormInputText } from './formInputs';
import {
  Grid,
} from '@mui/material';

const issueOptionsForChurn = [
  {
    label: "Performed as expected but didn't meet needs or use-case",
    value: "Performed as expected but didn't meet needs or use-case",
  },
  {
    label: "Didn't like hardware (size, design, mounting, etc)",
    value: "Didn't like hardware (size, design, mounting, etc)",
  },
  {
    label: "Didn't like software (dashboard, reports, etc)",
    value: "Didn't like software (dashboard, reports, etc)",
  },
]
const issueOptionsForIssue = [
  {
    label: "Performance Issue - Does not stream frames",
    value: "Performance Issue - Does not stream frames",
  },
  {
    label: "Performance Issue - Bad data quality",
    value: "Performance Issue - Bad data quality",
  },
  {
    label: "Performance Issue - Loud fan",
    value: "Performance Issue - Loud fan",
  },
  {
    label: "Packaging or device was damaged in shipping",
    value: "Packaging or device was damaged in shipping",
  },
  {
    label: "Wouldn't provision (Setup App issue, etc)",
    value: "Wouldn't provision (Setup App issue, etc)",
  },
  {
    label: "Networking issue (can't connect to internet, etc)",
    value: "Networking issue (can't connect to internet, etc)",
  },
  {
    label: "Wouldn't power on",
    value: "Wouldn't power on",
  },
]

function Issue() {
  const { watch, resetField } = useFormContext();
  const returnReason = watch("returnReason");

  useEffect(() => {
    console.log('return reason changed', returnReason)
    resetField("issue")
  }, [returnReason, resetField]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <FormInputSelect
          name="issue"
          label="What is the issue?"
          options={watch('returnReason') === "churn" ? issueOptionsForChurn : issueOptionsForIssue}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <FormInputText
          name="notes"
          label="What symptoms does the the customer report?"
          multiline
        />
      </Grid>
    </Grid>
  )
}

export default Issue;
